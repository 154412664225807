import moment from 'moment'
import Cell from 'src/components/Table/Cell'
import { NumberInput } from 'src/components/TextInput/TextInput'
import { useProjectsContext } from 'src/data/ProjectsContext'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import { DetailedProject } from 'src/packages/api/types/Project'
import { MonthYearPair } from 'src/pages/employees/helper'
import { getNumberOfHoursAvailable } from 'src/pages/helper'
import styled from 'styled-components'

interface MonthListProps {
  project: DetailedProject
  monthList: MonthYearPair[]
  employee: DetailedEmployee
  save: () => void
}

interface MonthListHoursStylingProps {
  months: number
  width: number
}

export const Container = styled.div<MonthListHoursStylingProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.months},
    ${(props) => props.width}px
  );
  grid-template-rows: 58px;
`

const MonthListHours: React.FC<MonthListProps> = ({
  save,
  project,
  monthList,
  employee,
}) => {
  const { updateHours } = useProjectsContext()

  return (
    <Container months={monthList.length + 1} width={110}>
      <Cell width={110}>
        <NumberInput
          placeholder="Hours"
          value={employee.spent_hours_past}
          onBlur={save}
          disabled
          pastHours
        />
      </Cell>
      {monthList.map((monthyear) => {
        const month = monthyear[0]
        const year = monthyear[1]
        const hours = employee.planned_hours || []
        const planned = hours
          .filter((x) => x.project_id === project.id)
          .find((x) => x.month === month && x.year === year)

        const onChangeHours = (newNumberHours: number) => {
          if (!isNaN(newNumberHours) && newNumberHours > 0) {
            updateHours(project.id, {
              hours: newNumberHours,
              month: month,
              year: year,
              employee_id: employee.id,
              project_id: project.id,
              type: 'project',
            })
          } else {
            updateHours(project.id, {
              hours: 0,
              month: month,
              year: year,
              employee_id: employee.id,
              project_id: project.id,
              type: 'project',
            })
          }
        }

        const curMonth = moment().month()
        const curYear = moment().year()
        const isEarlierYear = year < curYear
        const isEarlierMonth = year === curYear && month < curMonth
        const isDisabled = isEarlierYear || isEarlierMonth

        return (
          <Cell key={month + ',' + year} width={110}>
            <NumberInput
              placeholder="Hours"
              value={planned?.hours || 0}
              onChange={(e: number) => onChangeHours(e)}
              onBlur={save}
              disabled={isDisabled}
            />
          </Cell>
        )
      })}
    </Container>
  )
}

export default MonthListHours

interface HoursAvailableProps {
  monthList: MonthYearPair[]
  employee: DetailedEmployee
  shiftRight?: boolean
}

export const HoursAvailable: React.FC<HoursAvailableProps> = ({
  monthList,
  employee,
  shiftRight,
}) => {
  return (
    <Container
      style={{ paddingLeft: shiftRight ? 110 : 0 }}
      months={monthList.length}
      width={110}
    >
      {monthList.map((monthyear) => {
        const month = monthyear[0]
        const year = monthyear[1]

        const planned_hours = employee.planned_hours || []
        const planned = planned_hours.filter(
          (x) => x.month === month && x.year === year
        )
        const plannedSum = planned
          .filter((x) => x.type === 'project')
          .map((x) => x.hours)
          .reduce((a, b) => a + b, 0)

        const leaveSum = planned
          .filter((x) => x.type === 'leave')
          .map((x) => x.hours)
          .reduce((a, b) => a + b, 0)

        const hoursAvailable = getNumberOfHoursAvailable(
          employee,
          month,
          year,
          plannedSum,
          leaveSum
        )

        return (
          <Cell key={month + ', ' + year} width={110}>
            <NumberInput
              placeholder="Hours"
              value={Number(hoursAvailable.toFixed(2))}
              disabled
            />
          </Cell>
        )
      })}
    </Container>
  )
}
